.trfan-game-page__top-container {
  max-width: 1160px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  gap: 40px;
  position: relative;
}

.trfan-game-page__header-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.trfan-game-page__header {
  font-size: 31px;
  line-height: 43px;
  font-family: "Raleway", sans-serif;
  text-align: start;
}

.trfan-game-page__rating {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.trfan-game-page__middle-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1160px;
  padding: 20px;
  margin: 0 auto;
}

.trfan-game-page__content {
  display: flex;
  gap: 60px;
}

.trfan-game-page__left {
  width: 240px;
  flex-shrink: 0;
}

.trfan-game-page__left p {
  position: relative;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.trfan-game-page__left p::after {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #bf218a;
  left: 0;
}

.trfan-game-page__images {
  display: flex;
  gap: 30px;
  max-width: 1160px;
  padding: 20px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.trfan-game-page__qr-container {
  background-color: #dbeaff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 211px;
}

.trfan-game-page__qr-body {
  max-width: 928px;
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}

.trfan-game-page__qr-text {
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1100px) {
  .trfan-game-page__images img {
    width: 149px;
    height: auto;
  }

  .trfan-game-page__images {
    gap: 15px;
  }
}

@media (max-width: 749px) {
  .trfan-game-page__rating-container {
    position: absolute;
    bottom: 0;
    left: 20px;
  }

  .trfan-game-page__rating-container p {
    font-size: 17px;
    line-height: 25px;
  }

  .trfan-game-page__top-container {
    padding-bottom: 72px;
    gap: 15px;
  }

  .trfan-game-page__header {
    font-size: 21px;
    line-height: 30px;
  }

  .trfan-game-page__rating {
    margin-top: 5px;
  }

  .trfan-game-page__top-container img {
    width: 150px;
    height: auto;
  }

  .trfan-game-page__content, .trfan-game-page__middle-container {
    flex-direction: column;
    gap: 15px;
  }

  .trfan-game-page__qr-body {
    flex-direction: column;
  }
}

@media (max-width: 516px) {
  .trfan-game-page__images {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .trfan-game-page__images img {
    width: 100%;
    height: auto;
  }
}
