@import url("/src/vendor/fonts/fonts.css");
@import url("/src/vendor/normalize.css");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.trfan-page {
  max-width: 1440px;
  margin: 0 auto;
  text-align: left;
  font-family: "Poppins", "Arial", sans-serif;
}

h1,
h2 {
  font-size: 53px;
  line-height: 64px;
  margin: 0;
  text-align: center;
  font-family: "Raleway", sans-serif;
}

p,
a,
li {
  margin: 0;
  color: #000;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.t-23 {
  font-size: 23px;
  line-height: 32px;
}

.t-17 {
  font-size: 17px;
  line-height: 25px;
}

.t-14 {
  font-size: 14px;
  line-height: 21px;
}

.text-center {
  text-align: center;
}

.trfan-privacy h1,
.trfan-privacy h2 {
  font-size: 31px;
  line-height: 40px;
  text-align: start;
}

.trfan-privacy {
  padding: 20px;
}

.trfan-privacy__body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1120px;
  margin: 0 auto;
}

@media (max-width: 1100px) {
  h1,
  h2 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 749px) {
  h1,
  h2 {
    font-size: 28px;
    line-height: 33px;
  }

  .t-23 {
    font-size: 18px;
    line-height: 25px;
  }

  .t-17 {
    font-size: 14px;
    line-height: 21px;
  }

  .trfan-privacy h1,
  .trfan-privacy h2 {
    font-size: 21px;
    line-height: 30px;
    text-align: start;
    margin-top: 10px;
  }

  .trfan-privacy__body {
    gap: 10px;
  }

  .trfan-privacy {
    padding: 10px 20px;
  }
}
