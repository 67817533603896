.trfan-footer {
  max-width: 1160px;
  margin: 60px auto 0;
  padding: 0 20px 40px;
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.trfan-footer__left {
  max-width: 564px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.trfan-footer__link {
  opacity: 0.5;
}

.trfan-footer__right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: end;
}

.trfan-footer__social {
  display: flex;
  gap: 20px;
}

@media (max-width: 749px) {
  .trfan-footer {
    flex-direction: column;
    gap: 10px;
    margin: 30px auto 0;
  }

  .trfan-footer__right {
    flex-direction: row-reverse;
  }

  .trfan-footer__left {
    gap: 10px;
  }
}