.trfan-main__cover {
  min-height: 423px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  background-color: #dafaff;
}

.trfan-main__body {
  max-width: 1120px;
  gap: 40px;
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  gap: 30px;
  min-height: 364px;
}

.trfan-main__text-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.trfan-main__header {
  font-size: 58px;
  line-height: 70px;
  color: #bf218a;
  text-align: start;
  margin-top: 15px;
}

.trfan-main__img {
  background: url("../../images/main-img.svg") center / contain no-repeat;
}

@media (max-width: 1100px) {
  .trfan-main__header {
    font-size: 42px;
    line-height: 59px;
  }

  .trfan-main__body {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 749px) {
  .trfan-main__header {
    font-size: 34px;
    line-height: 41px;
  }

  .trfan-main__body {
    flex-direction: column;
    display: flex;
    gap: 15px;
  }

  .trfan-main__img {
    height: 265px;
  }
}
