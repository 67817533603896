.trfan-about {
  background-color: #dbeaff;
  min-height: 350px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
}

.trfan-about__body {
  max-width: 928px;
  gap: 40px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 749px) {
  .trfan-about__body {
    gap: 20px;
  }
}
