.trfan-faq {
  max-width: 968px;
  margin: 80px auto 40px;
  padding: 20px;
}

.trfan-faq__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.trfan-faq__item {
  background-color: #dafaff;
  min-height: 64px;
  border-radius: 10px;
  padding: 15px 40px 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.trfan-faq__arrow {
  position: absolute;
  right: 15px;
  top: 28px;
}

.trfan-faq__answer {
  display: none;
}

.visible {
  display: block;
}

.rotate {
  transform: rotate(180deg);
}

@media (max-width: 749px) {
  .trfan-faq {
    margin: 20px auto 20px;
  }
}
