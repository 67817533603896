.trfan-games {
  padding: 0 20px;
  max-width: 1160px;
  margin: 40px auto;
}

.trfan-games__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}

.trfan-games__item {
  width: 160px;
  height: 211px;
  background-color: #dbeaff;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 580px) {
  .trfan-games__item {
    width: 100%;
    height: 100%;
  }

  .trfan-games__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .trfan-games__img {
    width: 100%;
    height: auto;
  }

  .trfan-games {
    margin: 20px auto;
  }
}
