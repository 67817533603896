.trfan-reviews {
  background-color: #dbeaff;
  padding: 20px;
  min-height: 394px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.trfan-reviews__body {
  margin: 0 auto;
  max-width: 928px;
  width: 100%;
}

.trfan-review__slide {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  min-height: 194px;
  width: 100%;
}

.trfan-review__name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trfan-review__rating {
  display: flex;
  gap: 10px;
  align-items: center;
}

.trfan-reviews__swiper {
  padding: 60px 0 40px;
}

.swiper-button-next {
  background: url("../../images/rev-btn.svg") center / contain no-repeat;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  right: 0;
}

.swiper-button-prev {
  background: url("../../images/rev-btn.svg") center / contain no-repeat;
  width: 44px;
  height: 44px;
  position: absolute;
  transform: rotate(180deg);
  top: 0;
  right: 65px;
}

.swiper-pagination-horizontal {
  align-items: center;
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background-color: #000;
}

.swiper-pagination-bullet-active {
  width: 8px;
  height: 8px;
}

@media (max-width: 1100px) {
}

@media (max-width: 749px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .trfan-reviews__swiper {
    padding: 20px 0 40px;
  }

  .trfan-reviews {
    min-height: 297px;
  }

  .trfan-reviews__body {
    max-width: 435px;
  }

  .trfan-review__slide {
    min-height: 152px;
  }
}
